/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true, /\.(gif|jpg|png|svg|mp4|webm|ogv)$/i);
const imagePath = (name) => images(name, true);


import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start();

require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery")



import Rails from "@rails/ujs";
window.Rails = Rails;

import 'bootstrap';

import "../stylesheets/application";


import moment from 'moment'

window.moment = moment

import "selectize/dist/js/standalone/selectize.min";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/ui/widgets/draggable";

import "trix";
import "@rails/actiontext";

require("src/direct_uploads");
require("src/trix-editor-overrides");
require("src/selectize");
require("src/application");
require("src/harvests");
require("src/pay");
require("src/unsaved_changes");
require("src/tables");
require("other/confirm-modal");
require("other/cocoon");
require("other/rails-sortable");
